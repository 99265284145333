



















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { MessageService } from "@/shared/services/message-service";
import { singleOrDoubleRowClick } from "@/utils/dblclickrow";
import { BaseDto } from "@/shared/dtos/base-dto";
import pantalla_modo_listaModule from "@/store/modules/pantalla_modo_lista-module";
import { UtilsString } from "@/utils/utils-string";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { pantalla_lista } from "@/shared/dtos/dynamic_tables/pantalla_lista";

@Component({
  components: { Xbtn: () => import("@/components/buttons/x-btn.vue") },
  $_veeValidate: { validator: "new" },
})
export default class Dynamic_table extends Vue {
  @Prop({ default: 0 }) id_tabla!: number;
  @Prop({ default: function() {} }) EventMethod!: (...args: any[]) => any[];
  @Prop({ default: [] }) permission_new!: string[];
  @Prop({ default: [] }) permission_update!: string[];
  @Prop({ default: [] }) permission_delete!: string[];

  public search: string = "";
  public datasource: any[] = [];

  public mounted() {
    this.refresh();
  }

  /* public get datasource() {
    return empresaModule.empresas;
  }*/
  public get pantalla() {
    if (pantalla_modo_listaModule.pantalla_modo_lista.pantalla === undefined) {
      return new pantalla_lista();
    }
    if (pantalla_modo_listaModule.pantalla_modo_lista.pantalla !== undefined) {
      ssmHttpService
        .get(
          API.webApiBase +
            pantalla_modo_listaModule.pantalla_modo_lista.pantalla.api_call
        )
        .then((x) => {
          this.datasource = x;
          if (this.EventMethod !== undefined) {
            this.datasource = this.EventMethod(this.datasource);
          }
        });
    }
    return pantalla_modo_listaModule.pantalla_modo_lista.pantalla;
  }

  public get Override_tipos_datos_boleanos() {
    if (pantalla_modo_listaModule.pantalla_modo_lista.columnas === undefined) {
      return [];
    }
    return pantalla_modo_listaModule.pantalla_modo_lista.columnas.filter(
      (x) => x.tipo_de_dato.nombre === "boolean"
    );
  }

  public get Override_tipos_datos_fechas() {
    if (pantalla_modo_listaModule.pantalla_modo_lista.columnas === undefined) {
      return [];
    }
    
    return pantalla_modo_listaModule.pantalla_modo_lista.columnas.filter(
      (x) => x.tipo_de_dato.nombre === "Date"
    );
  }

  public get Override_tipos_datos() {
    if (pantalla_modo_listaModule.pantalla_modo_lista.columnas === undefined) {
      return [];
    }
    return pantalla_modo_listaModule.pantalla_modo_lista.columnas;
  }

  public get headers() {
    let header: any[] = [];
    if (pantalla_modo_listaModule.pantalla_modo_lista.columnas === undefined) {
      return header;
    }

    for (
      let i = 0;
      i < pantalla_modo_listaModule.pantalla_modo_lista.columnas.length;
      i++
    ) {
      let item = pantalla_modo_listaModule.pantalla_modo_lista.columnas[i];
      header.push({ text: item.nombre, value: item.data_bilding });
    }

    header.push({ text: "", value: "acciones", align: "end" });

    return header;
  }

  //public datos_dom_table

  public get_valor(obj: any, prop: string) {
    return UtilsString.ValueOfValueName(obj, prop);
  }

  /**
   * editar
   */
  public editar_rw(obj: BaseDto) {
    this.$emit("edit", new BaseDto(obj));
  }

  /**
   * add_new
   */
  public add_new() {
    this.$emit("add");
    this.$emit("new");
  }
  /**
   *eliminar
   */
  public eliminar(obj: BaseDto) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar el registro ?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          this.$emit("delete", new BaseDto(obj));
        }
      }
    );
  }

  public refresh() {
    if (this.id_tabla === 0) {
      pantalla_modo_listaModule
        .getpantalla_modo_lista(this.$route.params.id)
        .then(() => this.refres_btn());
    } else {
      pantalla_modo_listaModule
        .getpantalla_modo_lista(this.id_tabla)
        .then(() => this.refres_btn());
    }
  }

  public refres_btn() {
    //@ts-ignore
    this.$refs["ref_add"].refrescar();
    //@ts-ignore
    this.$refs["ref_update"].refrescar();
    //@ts-ignore
    this.$refs["ref_borrar"].refrescar();
  }

  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
}
